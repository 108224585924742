import 'bootstrap/js/collapse';
import 'bootstrap/js/dropdown';
import 'element-closest-polyfill';
import { addProductToCart, redRawCount } from './front/cart';
import Swal from './front/sweetalert/polyfilled-sweetalert2';
import { isHtmlFileInput } from './HtmlPredicates/predicates';
import './stimulus-bootstrap';
$(() => {
    const $clientRequestFormPlaceholder = $('.js-client-request-form');
    $clientRequestFormPlaceholder.on('click', '.js-client-request-submit', (e) => {
        e.preventDefault();
        const { target } = e;
        const form = target.form;
        const url = form?.action;
        const formData = new FormData();
        form.querySelectorAll('input,textarea').forEach((input) => {
            if (!isHtmlFileInput(input)) {
                formData.append(input.name, input.value);
                return;
            }
            if (isHtmlFileInput(input) && input.files?.length) {
                formData.append(input.name, input.files[0]);
                return;
            }
        });
        $(target).prepend('<i class="fas fa-spin fa-circle-notch js-spinner"></i> ').prop('disabled', true);
        $.ajax({
            url,
            data: formData,
            type: 'post',
            processData: false,
            contentType: false,
        })
            .done((response) => {
            $clientRequestFormPlaceholder.html(response);
        })
            .fail(() => {
            $(target).find('.js-spinner').remove();
            $(target).prop('disabled', false);
        });
    });
    $('.js-submit-search').on('click', function () {
        const $partNumberInput = $('.js-part-number');
        const input = $partNumberInput.val();
        if (input == '') {
            Swal.fire('Įveskite kodą');
            return false;
        }
        const removedWhiteSpaceInput = input.trim().replace(/\s/g, '');
        $partNumberInput.val(removedWhiteSpaceInput);
        $('#search_form').trigger('submit');
    });
    const _global = (window ?? global);
    _global.addProductToCart = addProductToCart;
    _global.redRawCount = redRawCount;
    redRawCount();
    const gdprCloseButton = document.querySelector('.js-g-d-p-r-close-button');
    if (gdprCloseButton) {
        gdprCloseButton.addEventListener('click', function (e) {
            e.preventDefault();
            const gdprBanner = document.querySelector('.js-g-d-p-r-banner');
            if (gdprBanner) {
                gdprBanner.hidden = true;
            }
            const d = new Date();
            d.setFullYear(d.getFullYear() + 5);
            document.cookie = `gdpr=1; expires=${d.toUTCString()}; path=/; secure=1; SameSite=Lax`;
        });
    }
});
