import '../../assets/styles/cart_steps.css';
import { Ecommerce } from './ecommerce';
const cartVersion = 'cartV4';
const ecommerce = new Ecommerce();
export function redRawCount() {
    const cartValue = sessionStorage.getItem(cartVersion);
    if (!cartValue) {
        return;
    }
    const cart = JSON.parse(cartValue);
    if (!cart) {
        return;
    }
    let quantitySum = 0;
    Object.values(cart).forEach(function (value) {
        quantitySum += Number(value.quantity);
    });
    $('.js-cart-count').text(String(quantitySum));
}
export function addProductToCart(id, part_number, quantity, price, type, minQuantity = 1, priceWithVat) {
    const cartValue = sessionStorage.getItem(cartVersion) ?? '{}';
    const cart = JSON.parse(cartValue) ?? {};
    if (cart.hasOwnProperty(id)) {
        delete cart[id];
    }
    cart[id] = {
        id,
        item: part_number,
        price: price,
        quantity: quantity,
        type,
        minQuantity,
        priceWithVat: priceWithVat
    };
    sessionStorage.setItem(cartVersion, JSON.stringify(cart));
    ecommerce.addToCart({
        items: [
            {
                index: 0,
                quantity,
                price: priceWithVat ?? +((price * (1 + taxRate)).toFixed(2)),
                item_id: "" + id,
                item_name: '' + part_number
            }
        ],
        currency: 'EUR',
        value: quantity
    });
    redRawCount();
}
const fadeTime = 0;
const taxRate = 0.21;
function fillCart() {
    const cartValue = sessionStorage.getItem(cartVersion) ?? '{}';
    const cart = JSON.parse(cartValue);
    const $cartTable = $('#cart_table');
    $cartTable.empty();
    Object.values(cart).forEach((value) => {
        $cartTable.append(`<tr class="product" data-id="${value.id}">
    <td data-th="Prekė">
        <strong>
            <span class="product-type">${value.type}</span> -
            <span class="product-item t-part-number">${value.item}</span>
        </strong>
    </td>
    <td data-th="Kaina">
        <span class="product-price">${value.price}</span>€
    </td>
    <td data-th="Kiekis">
        <input
            type="number"
            name="quantity[]"
            min="${value.minQuantity ?? 1}"
            class="form-control text-center product-quantity t-quantity js-quantity"
            value="${value.quantity}"/>
    </td>
    <td data-th="Suma" class="text-center product-line-price t-price">${(parseFloat(String(value.price).replace(/\s+/, '')) * value.quantity).toFixed(2)}€
    </td>
    <td data-th="Suma su Pvm" class="text-center">
        ${value.priceWithVat ?? (parseFloat(String(value.price).replace(/\s+/, '')) * value.quantity * (taxRate + 1)).toFixed(2)}€
    </td>
    <td class="actions text-right">
        <input type="hidden" name="partId[]" value="${value.id}"/>
        <button type="button" class="btn btn-danger btn-sm js-remove-item">
            <span class="glyphicon glyphicon-trash"></span>
        </button>
    </td>
</tr>`);
    });
    recalculateCart();
}
function removeItem(removeButton) {
    const $productRow = $(removeButton).parents('tr');
    const id = $productRow.data('id');
    $productRow.slideUp(fadeTime, () => {
        $productRow.remove();
        removeProductFromCart(id);
        recalculateCart();
    });
}
function recalculateCart() {
    let subtotal = 0;
    $('.product').each(function () {
        subtotal += parseFloat($(this).children('.product-line-price').text().trim().replace(/\s+/, ''));
    });
    var tax = subtotal * taxRate;
    var total = subtotal + tax;
    $('.totals-value').fadeOut(fadeTime, () => {
        $('.cart-subtotal').html(subtotal.toFixed(2));
        $('.cart-tax').html(tax.toFixed(2));
        $('.cart-total').html(total.toFixed(2));
        if (total == 0) {
            $('.js-checkout').addClass('hidden');
        }
        else {
            $('.js-checkout').removeClass('hidden');
        }
        $('.totals-value').fadeIn(fadeTime);
    });
}
function updateQuantity(quantityInput) {
    const $productRow = $(quantityInput).parents('tr');
    const price = parseFloat($productRow.find('.product-price').text().replace(/\s+/, ''));
    const priceWithTax = parseFloat($productRow.find('.catalog-price').text().replace(/\s+/, ''));
    let quantity = Number(quantityInput.value);
    const id = $productRow.data('id');
    const part_number = $productRow.find('.product-item').text();
    const part_type = $productRow.find('.product-type').text();
    const minRequiredQuantity = Number(quantityInput.min);
    if (quantity < minRequiredQuantity) {
        quantity = minRequiredQuantity;
        quantityInput.value = String(quantity);
    }
    const linePrice = price * quantity;
    $productRow.children('.product-line-price').each(function () {
        $(this).fadeOut(fadeTime, function () {
            $(this).text(linePrice.toFixed(2) + '€');
            recalculateCart();
            addProductToCart(id, part_number, quantity, price, part_type, minRequiredQuantity, priceWithTax);
            $(this).fadeIn(fadeTime);
        });
    });
}
function removeProductFromCart(id) {
    const cart = JSON.parse(sessionStorage.getItem(cartVersion) ?? '');
    if (!cart) {
        return;
    }
    delete cart[id];
    sessionStorage.setItem(cartVersion, JSON.stringify(cart));
    redRawCount();
}
$(() => {
    redRawCount();
    fillCart();
    $('.product-quantity').on('change', ({ target }) => {
        updateQuantity(target);
    });
    $('#cart_form').on('click', '.js-remove-item', (e) => {
        removeItem(e.target);
    });
});
const getECommerceData = () => {
    const cartValue = sessionStorage.getItem(cartVersion) ?? '{}';
    const cart = JSON.parse(cartValue);
    const entries = Object.entries(cart);
    if (!entries.length)
        return null;
    return {
        currency: 'EUR',
        value: Object.values(cart).reduce((acc, next) => acc + next.quantity, 0),
        items: entries.map(([id, item], index) => {
            return {
                index,
                item_name: item.item,
                item_id: id,
                item_brand: item.type,
                quantity: item.quantity,
                price: item.priceWithVat ?? +(item.price * (1 + taxRate) * item.quantity).toFixed(2),
            };
        })
    };
};
const viewCartHandler = () => {
    const data = getECommerceData();
    if (data)
        ecommerce.viewCart(data);
};
const checkoutHandler = () => {
    $('.js-checkout').on('click', () => {
        const data = getECommerceData();
        if (data)
            ecommerce.beginCheckout(data);
    });
};
document.addEventListener('DOMContentLoaded', viewCartHandler);
document.addEventListener('DOMContentLoaded', checkoutHandler);
