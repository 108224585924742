export class Ecommerce {
    get dataLayer() {
        return window.dataLayer ?? [];
    }
    clearLayer = () => {
        this.dataLayer.push({ ecommerce: null });
    };
    addToCart = (ecommerce) => {
        this.clearLayer();
        return this.dataLayer.push({ event: 'add_to_cart', ecommerce });
    };
    viewCart = (data) => {
        this.clearLayer();
        return this.dataLayer.push({ event: 'view_cart', ecommerce: data });
    };
    beginCheckout = (data) => {
        this.clearLayer();
        return this.dataLayer.push({ event: 'begin_checkout', ecommerce: data });
    };
    purchase = (data) => {
        this.clearLayer();
        return this.dataLayer.push({ event: 'purchase', ecommerce: data });
    };
}
